import { Controller } from "@hotwired/stimulus"
import exifr from 'exifr'
import _ from 'lodash';

export default class extends Controller {
  static values = { status: { type: String, default: "..." } }
  static targets = ['latitude', 'longitude', 'latitudeDisplay', 'longitudeDisplay', 'timestamp', 'timestampDisplay', 'status', 'submit', 'input']
  static outlets = ['simple-map']

  connect() {
    this.inputTarget.addEventListener('change', this.handlePhotoSelected.bind(this));
  }

  async handlePhotoSelected(event) {
    this.submitTarget.disabled = true;
    this.simpleMapOutlet.resetMap();

    let exif = await exifr.parse(event.target.files[0])

    // try to set the date to the earliest photo date
    let date = exif.DateTimeOriginal
    // if the date is valid, set the date input to the earliest date
    if (!isNaN(date)) {
      console.log(date)
      this.timestampTarget.value = this.convertToDateLocalString(date)
      this.timestampDisplayTarget.innerHTML = new Date(date).toLocaleString()
    } else {
      this.timestampTarget.value = null;
      this.timestampDisplayTarget.innerHTML = "N/A";
    }

    if (exif.latitude && exif.longitude) {
      this.latitudeTarget.value = exif.latitude
      this.latitudeDisplayTarget.innerHTML = exif.latitude.toFixed(5)
      this.longitudeTarget.value = exif.longitude
      this.longitudeDisplayTarget.innerHTML = exif.longitude.toFixed(5)

      this.statusValue = '<i class="fa-solid fa-circle-check text-success"></i> Found';

      this.simpleMapOutlet.addMarker({latitude: exif.latitude, longitude: exif.longitude, content: `Photo location: ${exif.latitude}, ${exif.longitude}`})
      this.submitTarget.disabled = false;
    } else {
      this.statusValue = '<i class="fa-solid fa-circle-exclamation text-warning"></i> Not found';
      this.submitTarget.disabled = true;
      this.latitudeTarget.value = null;
      this.longitudeTarget.value = null;
      this.latitudeDisplayTarget.innerHTML = "N/A";
      this.longitudeDisplayTarget.innerHTML = "N/A";
    }

    // try to set the moment location to the average of all the photos
    // flat map the exif data to only include photos with GPS data
    //let points = exifs.flatMap(exif => { if (exif.latitude && exif.longitude) return [[exif.latitude, exif.longitude]]; else return [] })
    //console.log(points)
    //if (points.length > 0) {
      //let averagePoint = points.reduce((acc, point) => [acc[0] + point[0], acc[1] + point[1]], [0, 0]).map(coord => coord / points.length)
      //this.latitudeTarget.value = averagePoint[0]
      //this.longitudeTarget.value = averagePoint[1]
      //this.showMap(averagePoint[0], averagePoint[1])
    //} else {
      //this.latitudeTarget.value = ""
      //this.longitudeTarget.value = ""
      //this.hideMap()
    //}
  }

  convertToDateLocalString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  statusValueChanged() {
    this.statusTarget.innerHTML = this.statusValue;
  }

  getLocation() {
    console.log("Getting location");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        this.latitudeDisplayTarget.innerHTML = position.coords.latitude
        this.latitudeTarget.value = position.coords.latitude

        this.longitudeDisplayTarget.innerHTML = position.coords.longitude
        this.longitudeTarget.value = position.coords.longitude

        this.timestampDisplayTarget.innerHTML = new Date(position.timestamp).toLocaleString()

        this.simpleMapOutlet.addMarker({ latitude: position.coords.latitude, longitude: position.coords.longitude, content: `Current location: ${position.coords.latitude}, ${position.coords.longitude}` });

        this.statusValue = "Obtained";
        this.submitTarget.disabled = false;
        //this.dispatch("location", { detail: { content: { latitude: position.coords.latitude, longitude: position.coords.longitude } } });
      },
      (error) => {
        this.statusValue = "Error";
        console.error(`Error: ${error}`);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }
}
